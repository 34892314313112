import React from 'react';
import Helmet from 'react-helmet';
import settings from '../../../settings';

import Layout from '../../components/mini/layout';
import SEO from '../../components/mini/seo';
import Team from '../../components/mini/team';

const favicon = `${settings.IMAGES_BASE_URL}/images/favicon/mini-favicon.png`;

const InstructorsPage = () => {
  return (
    <Layout>
      <SEO title="THE MINI DRIVING EXPERIENCE | Instructors" />
      <Helmet>
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
      <div className="instructors-page">
        <section className="hero__header">
          <div className="container">
            <div className="hero__header__content">
              <h2 className="linethrough-header">Instructors</h2>
            </div>
          </div>
        </section>
        <Team />
      </div>
    </Layout>
  );
};

export default InstructorsPage;
